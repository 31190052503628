import React, { FC } from "react"
import styled from "styled-components"

// Components
import CustomPrismicTitle from "../../../components/atoms/CustomPrismicTitle"
import Button from "../../../components/atoms/Button"
import ServiceCard from "./ServiceCard"

// Custom hooks
import { useLocale } from "../../../hooks/useLocale"

// Styles
import mq from "../../../styles/breakpoints"

//  Types
import {
  IServiceCard,
  IServicesCards,
} from "../../../prismic-types/slices/services-cards"

const ServicesCards: FC<IServicesCards> = ({ title, button, cards }) => {
  const { getLocalizedLink } = useLocale()
  return (
    <Conatiner>
      <HeaderSection>
        <div>
          <CustomPrismicTitle data={title} />
        </div>
        {!!button?.href && (
          <StyledButtonFirst
            fullWidth={false}
            href={getLocalizedLink(button.href)}
            label={button?.label}
            variant="secondary"
          />
        )}
      </HeaderSection>
      <CardsSection>
        {cards.map((card: IServiceCard) => (
          <ServiceCard
            key={card.heading}
            serviceType={card.serviceType}
            serviceLevel={card.serviceLevel}
            serviceTypeColor={card.serviceTypeColor}
            title={card.title}
            heading={card.heading}
            description={card.description}
            button={card.button}
            image={card.image}
          />
        ))}
      </CardsSection>

      {!!button?.href && (
        <StyledButtonSecond
          fullWidth={true}
          href={getLocalizedLink(button.href)}
          label={button.label}
          variant="secondary"
        />
      )}
    </Conatiner>
  )
}

export default ServicesCards

const Conatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledButtonFirst = styled(Button)`
  display: none;

  ${mq.from.XSM`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const CardsSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const StyledButtonSecond = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${mq.from.XSM`
  display: none;
  `}
`
