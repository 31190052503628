import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Custom Hooks
import { useLocale } from "../../hooks/useLocale"

// Components
import ContentWrapper from "../../components/ContentWrapper"
import Seo from "../../components/Seo"
import Intro from "../../components/Intro"
import BottomSection from "../../components/BottomSection"
import LogoBanner from "../../components/LogoBanner"
import ServicesCards from "../../components/services/services-cards"

// Constants
import { getUrl } from "../../constants/paths"

// Styles
import colors from "../../styles/colors"
import mq from "../../styles/breakpoints"

// Types
import { IServicesPage } from "../../prismic-types/services-page"
import { IntroVariant } from "../../prismic-types/homepage"

interface IProps {
  content: IServicesPage
}

const Services: React.FC<IProps> = ({ content }) => {
  const slug = "services"
  const { isDutch, getLocalizedLink } = useLocale()

  const [marketingSection] = content.marketingSections
  const servicesCardsSlice = content.slicesBody.find(
    (slice: any) => slice.slice_type === "services_cards"
  )

  return (
    <>
      <Seo
        title={content.meta.title}
        description={content.meta.description}
        dateModified={content.meta.modifiedDate}
        datePublished={content.meta.publishedDate}
        lang={content.meta.lang}
        slug={slug}
        imageUrl={content.intro.desktopImg.imageUrl}
        breadCrumbItems={[{ name: "Services", url: slug }]}
      />
      <Intro
        {...content.intro}
        buttonVariant="tertiary"
        buttonLink={getLocalizedLink("/contact")}
        buttonLabel={content.intro.button.label}
        color="purple"
        shapeColor={colors.white}
      />
      <ServicesList>
        <ContentWrapper>
          {servicesCardsSlice && (
            <ServicesCards
              title={servicesCardsSlice.primary.services_cards_heading.raw}
              button={{
                label: servicesCardsSlice.primary.services_cards_button_label,
                href: servicesCardsSlice.primary.services_cards_button_link,
              }}
              cards={servicesCardsSlice.items
                .filter((item: any) => item.services_cards_template.document)
                .map((item: any) => {
                  const docData = item.services_cards_template?.document?.data
                  const docUid = item.services_cards_template?.document?.uid

                  return {
                    heading: docData.heading,
                    title: docData.title,
                    description: docData.description,
                    image: docData.thumbnail_image,
                    serviceLevel: docData.service_level,
                    serviceType: docData.service_type,
                    serviceTypeColor: docData.service_type_color,
                    button: {
                      label: isDutch ? "Meer weten" : "Learn more",
                      href: `/services/${docUid}`,
                    },
                  }
                })}
            />
          )}
        </ContentWrapper>
      </ServicesList>
      <StyledLogoBanner {...content.logos} />
      {marketingSection && (
        <BottomSection
          title={marketingSection.title || ""}
          text={marketingSection.text || ""}
          buttonLabel={marketingSection.button?.label}
          buttonLink={getLocalizedLink(getUrl(marketingSection.button?.link))}
          sectionId="our-story"
          titleColor={colors.purple}
          textColor={colors.purple}
          buttonVariant="primary"
          background={{
            type: "imageWithGradient",
            imageUrl: marketingSection.backgroundImage?.url || "",
            gradientStartColor: colors.OffWhite,
            gradientEndColor: colors.purpleDarkestDim,
            gradientStartPercentage: 17,
            gradientEndPercentage: 100,
          }}
        />
      )}
    </>
  )
}

const ServicesList = styled.div`
  background: linear-gradient(
      180deg,
      ${colors.white} 0%,
      ${colors.blueSoft} 100%
    ),
    ${colors.greyLight};
  padding: 64px 0;

  ${mq.from.M`
    padding: 88px 0;
  `};

  ${mq.from.L`
    padding: 120px 0;
  `};
`

const StyledLogoBanner = styled(LogoBanner)`
  background: ${colors.main["700"]};
`

export const query = graphql`
  query ServicesPage($locale: String!) {
    prismicServicesPage(lang: { eq: $locale }) {
      lang
      first_publication_date
      last_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Intro
        intro_title {
          text
        }
        intro_text {
          html
        }
        intro_label
        intro_cta_label
        intro_cta_link
        intro_image {
          alt
          url
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        marketing_section {
          button_label
          button_link
          title {
            text
          }
          text {
            html
          }
          image {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
            url
            alt
          }
          background_image {
            url
            alt
          }
        }
        body {
          ... on PrismicServicesPageBodyServicesCards {
            id
            slice_type
            primary {
              services_cards_button_label
              services_cards_button_link
              services_cards_heading {
                raw
              }
            }
            items {
              services_cards_template {
                document {
                  ... on PrismicServicesTemplate {
                    id
                    uid
                    data {
                      title
                      service_type
                      service_level
                      service_type_color
                      heading
                      description
                      thumbnail_image {
                        alt
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicHomePage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            slides {
              service_type
              label
              level
              title {
                text
              }
              text {
                html
              }
              button_label
              button_link
              image {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
    allPrismicEvaluationDetail(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            testimonials {
              logo {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
    manifestoPage: prismicManifestoPage(lang: { eq: $locale }) {
      data {
        # Logos
        logos_label
      }
    }
  }
`

export default ({ data }: any) => {
  const {
    data: raw,
    lang,
    first_publication_date,
    last_publication_date,
  } = data.prismicServicesPage
  const logoData =
    data.allPrismicEvaluationDetail.edges[0].node.data.testimonials
  const manifestoPage = data.manifestoPage
  const slicesBody = raw.body

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: first_publication_date,
    modifiedDate: last_publication_date,
    lang,
  }

  const intro = {
    title: raw.intro_title?.text,
    text: raw.intro_text?.html,
    label: raw.intro_label,
    desktopImg: {
      alt: raw.intro_image?.alt,
      url: raw.intro_image?.fluid,
      imageUrl: raw.intro_image?.url,
    },
    button: {
      label: raw.intro_cta_label,
      link: raw.intro_cta_link,
    },
    variant: "page" as IntroVariant,
  }

  const formattedServices = data.allPrismicHomePage.edges[0].node.data.slides.map(
    (slide: any) => ({
      title: slide.title.text,
      type: slide.service_type,
      label: slide.label,
      level: slide.level,
      text: slide.text.html,
      button: {
        link: slide.button_link,
        label: slide.button_label,
      },
      image: {
        alt: slide.image?.alt,
        url: slide.image?.fluid,
        imageUrl: slide.image?.url,
      },
    })
  )

  const logos = {
    label: manifestoPage.data.logos_label,
    logos: logoData.map((testimonial: any) => ({
      alt: testimonial.logo?.alt,
      url: testimonial.logo?.fluid,
    })),
  }

  const marketingSections = raw.marketing_section.map((section: any) => ({
    title: section.title.text,
    text: section.text.html,
    button: {
      link: section.button_link,
      label: section.button_label,
    },
    image: {
      alt: section.image?.alt,
      url: section.image?.fluid,
    },
    backgroundImage: {
      alt: section.background_image?.alt,
      url: section.background_image?.url,
    },
  }))

  const content: IServicesPage = {
    meta,
    intro,
    services: formattedServices,
    marketingSections,
    logos,
    slicesBody,
  }

  return <Services content={content} />
}
